.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 33px;

  @media (max-width: 1050px) {
    display: none;
  }
}

.containerWrapper {
  background: #fff;
}
.headerinfo{
  background: #ffff00;
  padding: 15px 0px;
  text-align: center;
  font-weight: bold;
}
.header {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 15px 0;

  & p {
    margin: 0;
  }
}

.firstItem {
  display: flex;
}

.item {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-right: 40px;
  }
}

.wrapper {
  margin-left: 7px;
}

.img {
  width: 18px;
  height: 18px;
}

.preTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #bebebe;
  line-height: 150%;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000;
  line-height: 150%;

  @media (max-width: 767px) {
    font-size: 16px;
  }

  & > a {
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }
}

.button {
  padding: 0 25px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  height: 39px;
  color: #ffffff;
  background: #eaa023;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease;

  &:hover {
    filter: drop-shadow(0px 15px 29px rgba(234, 160, 35, 0.51));
  }
}

.secondItem {
  display: flex;
  align-items: center;

  & a {
    color: #000000;
  }

  & > button.active {
    @media (max-width: 600px) {
      display: none;
    }
  }

  &.mobile {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    & > *:last-child:not(.languageMobile) {
      @media (max-width: 400px) {
        display: none;
      }
    }
  }
}

.changeLanguage {
  font-family: 'Raleway';
  background: linear-gradient(90deg, #d9d9d9 2.05%, rgba(217, 217, 217, 0) 74.18%);
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  padding: 3.5px 11px;
  position: relative;

  & > span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
    padding: 7px 13px;
    z-index: 2;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  & > span > a {
    color: #000000;
  }

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    background-color: #fff;
    border-radius: 30px;
    height: calc(100% - 10px);
    width: calc(33% - 11px);
    z-index: 1;
    transition: all 0.3s ease;
  }

  &.left {
    &:before {
      left: 11px;
    }
  }

  &.center:before {
    left: 36%;
  }

  &.right {
    &:before {
      left: calc(100% - 11px);
      transform: translateX(-100%);
    }
  }
}

.secondRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (max-width: 1050px) {
    padding-top: 30px;
  }

  @media (max-width: 600px) {
    padding-top: 20px;
  }

  @media (max-width: 950px) {
    justify-content: flex-start;
  }
}

.logo {
  display: flex;
  flex-direction: column;

  @media (max-width: 950px) {
    margin-right: 30px;
  }

  & > span {
    padding-left: 5px;
  }
}

.counter {
  position: absolute;
  top: -3px;
  font-size: 12px;
  background: #eaa023;
  border-radius: 50%;
  padding: 0 5px;
  color: #fff;

  &.red {
    background: #e74137;
  }
}

.itemImageWrapper {
  position: relative;
  height: 18px;
  padding-right: 20px;
  margin-top: -13px !important;

  & > img {
    height: 18px;
    margin-top: 5px;
  }

  @media (max-width: 1150px) {
    margin-top: -3px;
  }
}

.secondWrapper {
  display: flex;
  align-items: center;
  flex: 0 0 calc(25% - 15px);
  justify-content: flex-end;

  & > *:first-child {
    margin-right: 27px;
  }

  @media (max-width: 1050px) {
    flex: 0 0 auto;
  }

  @media (max-width: 400px) {
    width: 100%;
    justify-content: center;
  }
}

.secondItem {
  cursor: pointer;
  align-items: center;
  display: flex;
  &:hover > span {
    text-decoration: underline;
  }
}

.secondItem > span {
  margin-left: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.searchWrapper {
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  padding: 6px 12px;

  &:not(.mobile) {
    @media (max-width: 767px) {
      display: none !important;
    }
  }

  &.mobile {
    margin-top: 30px;
    @media (min-width: 767px) {
      display: none;
    }

    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
}

.logo {
  flex: 0 0 20%;

  & > span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

.searchWrapper {
  flex: 0 0 55%;
  display: flex;
}

.searchInput {
  height: 24px;
  background: transparent;
  outline: none;
  border: none;
  flex: 1 1 auto;
  padding-left: 8px;
}

.menu {
  display: none;
  margin-bottom: 0;

  @media (min-width: 950px) {
    display: flex;
    justify-content: space-around;
    margin-top: 27px;
    padding: 0;
  }

  & a {
    color: #000;
  }

  & > li:hover {
    text-decoration: underline;
  }

  @media (max-width: 1100px) {
    justify-content: space-between;
  }
}

.hamburgerLines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  right: 0;
  border: none;
  background: transparent;
  padding: 0;
  z-index: 45;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1050px) {
    display: none;
  }

  @media (max-width: 400px) {
    height: 22px;
    width: 28px;
  }

  &.modify {
    top: 20px;
    right: 10px;
  }
}

.hamburgerLines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #eaa023;
}

.hamburgerLines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerLines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburgerLines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.hamburgerLines.active .line1 {
  transform: rotate(47deg);
  margin-top: -1px;
}

.hamburgerLines.active .line2 {
  transform: scaleY(0);
}

.hamburgerLines.active .line3 {
  transform: rotate(-47deg);
}

.mobileMenu {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  min-height: 100vh;
  width: 100vw;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  background: #eeedea;
  z-index: 50;
  padding: 20px 15px;
  transform: translateY(-100%);

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.menuMobile {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  font-size: 22px;

  & a {
    color: #000;
  }

  & > * {
    line-height: 200%;
    text-align: center;
    font-size: 26px;
  }
}

.mobileBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  @media (min-width: 1050px) {
    display: none;
  }

  @media (max-width: 600px) {
    margin-top: 20px;
  }

  & > .secondItem {
    @media (max-width: 400px) {
      display: none;
    }
  }
}

.thirdWrapper {
  @media (max-width: 1050px) {
    display: none;
  }
}

.desktopWrapper {
  & > * {
    @media (max-width: 1050px) {
      display: none;
    }
  }
}

.itemWrapper {
  display: flex;
  justify-content: center;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
      justify-content: center;
      margin-right: 0 !important;
    }

    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 15px !important;
  }

  & > *:first-child {
    position: relative;
    font-size: 16px;
    line-height: 19px;
    height: 25px;
    display: flex;
    align-items: center;

    & > a {
      color: #a9a9a9;
    }
  }

  & > *:not(:first-child) {
    position: relative;
    font-size: 16px;
    line-height: 19px;
    margin-left: 45px;
    height: 25px;
    display: flex;
    align-items: center;

    & > a {
      color: #a9a9a9;
    }

    &:after {
      content: '';
      background: url('../../../media/main/breadcrumb-arrow.svg');
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(100% + 10px);
    }
  }

  & > *:last-child {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    display: flex;
    align-items: center;
    border: 1px solid #eaa023;
    border-radius: 30px;
    padding: 0 16px;
    height: 39px;

    & > a {
      color: #a9a9a9;
    }
  }
}
